body {
  background-color: var(--primary-color);
  font-family: "Open Sans Condensed", sans-serif;
}

h1 {
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
  margin-right: 1.5rem;
}

h2 {
  color: var(--complementary-color);
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 2rem;
}

p {
  color: var(--complementary-color);
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.center {
  text-align: center;
}
