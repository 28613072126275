.page__wrapper {
  padding: 2rem 0;
  margin: 0 auto;
  width: 80%;
  max-width: 50rem;
}

.page__wrapper--small {
  padding: 2rem 0;
  margin: 0 auto;
  width: 60%;
  max-width: 50rem;
}

.page__header {
  background-color: var(--primary-color-dark); // the header is always dark
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: var(--complementary-color-dark);
  }
}

.page__logo {
  width: 70px;
  margin-left: 1rem;
  margin-bottom: .5rem;
}

.page__logout {
  width: 40px;
  cursor: pointer;
  margin-right: 1rem;
}

.page__content {
  text-align: center;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.google__input {
  border-radius: 30px;
  border: none;
  padding: 1rem;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
}

.input {
  border: none;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
}

.google-icon {
  position: absolute;
  width: 2rem;
  right: 1rem;
  bottom: .6rem;
}

.button {
  background: #FFCF06;
  border: 1px solid #FFCF06;
  color: var(--primary-color-dark);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
  padding: 1rem;
}

.login__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left {
  text-align: left;
}
