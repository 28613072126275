.switch,
.toggle {
  height: 2.8rem;
  border-radius: 100px;
}

.switch {
  // width: 100%;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 100px;
  position: relative;
  // margin: 1.8rem 0 4rem 0;
  cursor: pointer;
}

.toggle {
  position: absolute;
  width: 50%;
  background-color: #FFF;
  box-shadow: 0 2px 15px rgba(0, 0, 0, .15);
  transition: transform .3s cubic-bezier(.25, .46, .45, .94);

  &-on {
    transform: translateX(100%);
    background-color: #1C446D;
  }
}

.names {
  font-size: 90%;
  font-weight: bolder;
  width: 65%;
  margin-left: 17.5%;
  margin-top: 8%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.light-on {
  color: white;
  opacity: .5;
}

.dark {
  opacity: .5;

  &-on {
    color: white;
    opacity: 1;
  }
}
