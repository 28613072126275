.AddLinkModal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  color: var(--complementary-color);
  position: absolute;
  // border: 1px solid rgb(204, 204, 204);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 600px;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
}

.AddLinkModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .329);
}

.Modal__Title {
  text-transform: uppercase;
  margin: 0;
}

.Modal__SectionTitle {
  font-weight: 700;
  margin-bottom: .5rem;
  margin-top: .5rem;
}

.Modal__ToggleButtonContainer {
  display: flex;
  flex-wrap: wrap;
}

.Modal__ToggleButton {
  flex: 1;
  flex-basis: calc(50% - 3rem);
  margin: 10px 10px 10px 0;
  text-transform: uppercase;
  text-align: start;
}

.Modal__CustomShortcut {
  display: flex;
  flex-direction: column;
}

.Modal__Input {
  font-weight: 100;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  input {
    //width: 50%;
    border-radius: 4px;
    border: none;
    padding: 1rem;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
    margin: 10px 10px 10px 0;
  }
}

.Modal__Actions {
  display: flex;
  justify-content: flex-end;

  .Modal__Action {
    padding: 15px;
    appearance: none;
    margin: 10px;
    border-radius: 3px;
    background: transparent;
    border: 1px solid grey;
    flex-basis: 25%;
    text-transform: uppercase;
    color: grey;
  }

  .Modal__Action-primary {
    background: #FFCF06;
    border: 1px solid #FFCF06;
    color: var(--primary-color-dark);
    box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
  }
}

.margin-1 {
  margin: 1rem;
}
