body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

:root {
  --primary-color-light: #E5E5E5;
  --primary-color-dark: #002C41;
  --complementary-color-light: #002C41;
  --complementary-color-dark: #E5E5E5;
  --primary-color: var(--primary-color-light);
  --complementary-color: var(--complementary-color-light);
}
